<template>
  <div class="home-page">
    <v-parallax
      :height="$vuetify.breakpoint.name == 'xs' ? 600 : 400"
      :src="
        $vuetify.breakpoint.name == 'xs' ? require('../assets/Product2Mobile.jpg') : require('../assets/Product2.jpg')
      "
      class="home-parallax image-header mx-n5 mb-10"
    >
      <v-container class="jumbotron">
        <v-row>
          <v-col cols="12" md="8">
            <h1 class="display-2 font-weight-bold mb-3">
              We are a top global wholesale trade and manufacturers of food raw materials and commodities.
            </h1>

            <p class="subheading font-weight-regular">
              With an experienced team that prioritizes customer satisfaction, we provide all your cornstarch raw
              materials and its derivative product for various industrial needs.
            </p>
            <v-btn class="mr-5" color="primary" to="/request-a-quote">Request a Quote</v-btn>
            <v-btn color="primary" outlined>View Products</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-parallax>
    <v-container>
      <!-- Product List -->
      <v-row>
        <Flicking :options="{ bound: true }" :plugins="arrowPlugins">
          <v-col cols="10" sm="6" md="3" class="px-xs-0 mb-5" v-for="(product, index) in productList" :key="index">
            <v-card class="mx-4" height="273">
              <v-row class="fill-height" align="center" justify="center">
                <v-hover v-slot="{ hover }">
                  <v-card tile flat :to="product.url">
                    <v-img
                      class="white--text align-end fill-height bottom-gradient"
                      :class="{ 'card-hover': hover }"
                      height="183px"
                      :src="require(`../assets/${product.image}`)"
                      gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                    >
                    </v-img>
                    <v-layout class="carousel-text-group">
                      <v-flex xs12>
                        <div class="text-subtitle-1 font-weight-bold mt-2">
                          {{ product.title }}
                        </div>
                        <div class="text-caption">
                          {{ product.description }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-hover>
              </v-row>
            </v-card>
          </v-col>
          <span v-if="$vuetify.breakpoint.name != 'xs'" slot="viewport" class="flicking-arrow-prev"></span>
          <span v-if="$vuetify.breakpoint.name != 'xs'" slot="viewport" class="flicking-arrow-next"></span>
        </Flicking>
      </v-row>
      <!-- Introduction -->
      <v-divider class="mt-10 mb-10"></v-divider>
      <div>
        <p class="gs_reveal">
          Universal Komoditi Nusantara is the first wet corn milling company in Indonesia. The company was established
          based on the spirit be a leader in the supply of food and raw materials that specialized in manufacturing
          cornstarch and its derivatives. With an experienced team that prioritizes customer satisfaction, we provide
          all your raw materials for various industrial needs.
        </p>
        <p class="gs_reveal">
          Our company has complex and integrated facilities that produce a wide range of cornstarch and derivative
          products. Our facility can produce 1,300 tons daily and will increase.
        </p>
        <p class="gs_reveal mb-2">The following are our products:</p>
        <ul class="gs_reveal">
          <li>Cornstarch</li>
          <li>Maltodextrin</li>
          <li>High Fructose Corn Syrup</li>
          <li>Corn Germ</li>
          <li>Corn Gluten Free</li>
          <li>Corn Gluten Meal</li>
          <li>Corn Steep Liquor</li>
        </ul>
      </div>
    </v-container>
    <Footer />
  </div>
</template>

<script>
// import HelloWorld from "@/components/HelloWorld";
import Footer from '@/components/Footer';
import { Flicking } from '@egjs/vue-flicking';
import { Arrow } from '@egjs/flicking-plugins';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

require('@egjs/vue-flicking/dist/flicking-inline.css');
require('@egjs/flicking-plugins/dist/arrow.css');

export default {
  name: 'Home',

  components: {
    // HelloWorld,
    Footer,
    Flicking,
  },
  methods: {
    animateFrom(elem, dir) {
      const direction = dir || 1;
      let x = 0;
      let y = direction * 100;
      const element = elem;
      if (element.classList.contains('gs_reveal_fromLeft')) {
        x = -100;
        y = 0;
      } else if (element.classList.contains('gs_reveal_fromRight')) {
        x = 100;
        y = 0;
      }
      element.style.transform = `translate(${x}px, ${y}px)`;
      element.style.opacity = '0';
      gsap.fromTo(
        element,
        { x, y, autoAlpha: 0 },
        {
          duration: 1.25,
          x: 0,
          y: 0,
          autoAlpha: 1,
          ease: 'expo',
          overwrite: 'auto',
        }
      );
    },

    hide(elem) {
      gsap.set(elem, { autoAlpha: 0 });
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray('.gs_reveal').forEach((elem) => {
      this.hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        onEnter: () => {
          this.animateFrom(elem);
        },
        onEnterBack: () => {
          this.animateFrom(elem, -1);
        },
        onLeave: () => {
          this.hide(elem);
        }, // assure that the element is hidden when scrolled into view
      });
    });
  },
  data: () => ({
    model: [],
    productList: [
      {
        title: 'Corn Starch',
        description:
          'Fine white powder with neutral odor and taste that is obtained from the endosperm of the corn kernel',
        url: '/product/corn-starch',
        image: 'corn-starch.jpg',
      },
      {
        title: 'Maltodextrin',
        description: 'Carbohydrate powder, mixture of monosaccharide, disaccharides, and polysaccharides',
        url: '/product/maltodextrin',
        image: 'maltodextrin.jpg',
      },
      {
        title: 'High Fructose Corn Syrup',
        description: 'Transparent to light yellow color syrup, obtained by the isomerization of glucose syrup',
        url: '/product/high-fructose-corn-syrup',
        image: 'glucose-syrup.jpg',
      },
      {
        title: 'Corn Germ',
        description: 'Part of the corn kernel where is separated from the starch, fiber and gluten',
        url: '/product/corn-germ',
        image: 'corn-germ.jpg',
      },
      {
        title: 'Corn Gluten Feed',
        description: 'Co-product of the corn wet-milling process and is the part of the corn kernel',
        url: '/product/corn-gluten-feed',
        image: 'corn-gluten-feed.jpg',
      },
      {
        title: 'Corn Gluten Meal',
        description:
          'Co-product of the corn wet-milling process which is produced after the starch and germ have been removed',
        url: '/product/corn-gluten-meal',
        image: 'corn-gluten-meal.jpg',
      },
      {
        title: 'Corn Steep Liquor',
        description:
          'Viscous brown color liquid, consisting entirely of the water-soluble components of corn steeped in water',
        url: '/product/corn-steep-liquor',
        image: 'corn-steep-liquor.jpg',
      },
    ],
    arrowPlugins: [new Arrow()],
    methods: {
      goToDetails() {
        this.$router.push('/news');
      },
    },
  }),
};
</script>

<!-- Local Style -->
<style scoped>
.headline-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.carousel-text-group {
  min-height: 115px;
}

.flicking-arrow-prev::before,
.flicking-arrow-prev::after,
.flicking-arrow-next::before,
.flicking-arrow-next::after {
  background-color: #c33c59;
}

.flicking-arrow-prev,
.flicking-arrow-next {
  top: 30%;
}

.flicking-arrow-prev:hover,
.flicking-arrow-next:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}

@media (max-width: 595px) {
  .v-btn {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
    max-width: auto;
    margin-bottom: 1rem;
  }
  .v-btn:last-child {
    margin-bottom: 3rem;
  }
  .jumbotron {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .jumbotron .display-2 {
    line-height: 2.5rem;
  }
  .home-parallax {
    height: auto !important;
  }
}
</style>

<!-- Global Style -->
<style>
.home-page .v-parallax__content {
  color: #333333;
  /* top: -41px; */
}

.card-hover .v-image__image {
  transform: scale(1.05);
}

@media (max-width: 1264px) {
  .home-page .v-parallax__image {
    left: 35% !important;
  }
}

@media (max-width: 595px) {
  .home-page .v-parallax__image {
    left: 50% !important;
  }
}
</style>
