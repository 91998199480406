<template>
  <v-app>
    <Navbar />
    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "App",
  components: {
    Navbar,
  },
  data: () => ({
    //
  }),
};
</script>

<style>
.theme--light.v-application {
  color: #333333 !important;
}

.v-card,
.v-list-item,
.v-image__image,
.v-card .headline-text,
.flicking-arrow-prev,
.flicking-arrow-next {
  cursor: pointer;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

/* BreadCrumbs */
.v-breadcrumbs {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

@media (max-width: 1264px) {
  .container {
    padding-left: 36px;
    padding-right: 36px;
  }
  .v-toolbar__content,
  .v-toolbar__extension {
    padding-left: 36px;
    padding-right: 36px;
  }
  .v-application .display-2 {
    font-size: 2.5rem !important;
  }
}

@media (max-width: 595px) {
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .v-toolbar__content,
  .v-toolbar__extension {
    padding-left: 16px;
    padding-right: 16px;
  }
  .v-application .display-2 {
    font-size: 2rem !important;
  }
}
</style>
