import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About'),
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: () => import('../views/Product'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact'),
  },
  {
    path: '/request-a-quote',
    name: 'RequestQuote',
    component: () => import('../views/RequestQuote'),
  },
  {
    path: '/story-book',
    name: 'StoryBook',
    component: () => import('../views/StoryBook/StoryBook'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
