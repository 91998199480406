import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import App from './App';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  // VueGoogleMaps,
  render: (h) => h(App),
}).$mount('#app');

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDRUh7Xyu457aFSfA0f9m96X_-6a7hIW-4',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
  // autobindAllEvents: false,
  installComponents: true
});
